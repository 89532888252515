<template>
    <svg :class="svgClass" :style="styleExternalIcon" aria-hidden="true" v-on="$listeners">
        <use :xlink:href="iconName" />
    </svg>
</template>

<script>
// doc: https://panjiachen.github.io/vue-element-admin-site/feature/component/svg-icon.html#usage

export default {
    name: 'SvgIcon',
    props: {
        iconClass: {
            type: String,
            required: true
        },
        className: {
            type: String,
            default: ''
        },
        width: {
            type: String
        },
        height: {
            type: String
        }
    },
    computed: {
        iconName () {
            return `#icon-${this.iconClass}`
        },
        svgClass () {
            if (this.className) {
                return 'svg-icon ' + this.className
            } else {
                return 'svg-icon'
            }
        },
        styleExternalIcon () {
            if(this.width && this.height) {
                return {
                    width: this.width,
                    height: this.height
                }
            } else {
                return {}
            }
        }
    }
}
</script>

<style scoped>
.svg-icon {
  width: 100%;
  height: 100%;
  fill: currentColor;
  overflow: hidden;
}

.svg-external-icon {
  background-color: currentColor;
  mask-size: cover !important;
  display: inline-block;
}

.icon-feature-bar-topic {
  width: 12px;
  height: 16px;
}
.icon-feature-bar-item {
  width: 24px;
  height: 24px;
}
</style>
