import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import Swiper from 'swiper'

import { Dialog, Input, Button, Image } from 'element-ui'
import './styles/index.css'
Vue.use(Dialog)
Vue.use(Input)
Vue.use(Button)
Vue.use(Image)

import './icons' // icon
import './styles/index.scss'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
