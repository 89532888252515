<template>
  <div class="main-container">
    <slot />
    <footer class="footer-wrapper">
      <div class="top">
        <div class="left">承办单位：北京艾佳德科技发展有限公司</div>
        <div class="right">主办单位：中国心理卫生协会</div>
      </div>
      <div class="middle">
        <div class="line"></div>
      </div>
      <div class="bottom">
        <div class="left">
          <div class="icon">
            <img
              src="~@/assets/img/切图_web_0628/logo-02.png"
              width="80px"
              height="80px"
              alt=""
            />
          </div>
          <div class="contact-us">
            <div class="contact-zh">联系我们</div>
            <div class="contacyt-cn">contact us</div>
          </div>
          <div class="line"></div>
          <div class="tel">
            <div class="tel-num">联系电话：13261637765</div>
            <div class="tel-title">心理咨询师专业技能培训项目管理中心</div>
          </div>
        </div>
        <div class="right">
          <div class="icon">
            <img
              src="~@/assets/img/切图_web_0628/中国心理卫生协会.png"
              width="80px"
              height="80px"
              alt=""
            />
          </div>
          <div :style="{ marginLeft: '20px' }">中国心理卫生协会</div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import SvgIcon from "../../components/SvgIcon.vue";
export default {
  components: { SvgIcon },
  data() {
    return {};
  },
  created() {
    console.log(this.$route);
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  .footer-wrapper {
    width: 100%;
    height: 274px;
    background: #282828;

    .top {
      display: flex;
      justify-content: space-around;
      font-size: 18px;
      color: #fff;
      padding-top: 50px;
    }
    .middle {
      height: 1px;
      display: flex;
      justify-content: center;
      margin-top: 30px;

      .line {
        width: calc(100% - 120px);

        background: #9b9b9b;
      }
    }
    .bottom {
      display: flex;
      justify-content: space-around;
      margin-top: 30px;
      color: #fff;

      .left {
        display: flex;
        align-items: center;

        .contact-us {
          margin-left: 20px;
          .contact-zh {
            font-size: 26px;
          }
          .contact-cn {
            font-size: 18px;
          }
        }

        .line {
          width: 1px;
          height: 60px;
          background: #9b9b9b;
          margin: 0 20px;
        }

        .tel {
          font-size: 18px;

          div + div {
            margin-top: 10px;
          }
        }
      }

      .right {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
