export const isMobile = () => {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag
}

export const multList = (list, num) => {
    const result = [];
    for (let i = 0; i < list.length; i++) {
      const page = Math.floor(i / num);
      if(!result[page]) {
          result[page] = []
      }
      result[page].push(list[i])
    }
    return result;
}